import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { ViewChild } from '@angular/core';
// dep
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// app
import { KeywordsFilterConfigService } from './../services/keywords-filter-config.service';
import { IDateRange, IFilterConfig, Granularity, IComparisonItem, IComparison } from '../../../constants/data-picker';
// import { IReportFilterConfigData } from '../../../constants/report-filter-config-data';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.scss']
})

export class ReportsFilterComponent extends BaseComponent implements OnInit {
  @Input() isReport = false;
  @Output() navigateBackEmitter = new EventEmitter(); // <- the parent should handle where to redirect
  @Output() dateSelectionChanged = new EventEmitter();
  // @ViewChild('dateRangePicker', { static: false }) dateRangePicker: DateRangePickerComponent;
  @ViewChild('dateRangePickerMonthly',{ static: false }) 
  
  public dateRange: DateRangePickerComponent
  public initialized = false;

  public minDateSelected: Date;
  public maxDateSelected: Date;

  public dateReady = false;

  public dateRangeObject: IFilterConfig = {
    dateRangeLimits: {
      minDate: null,
      maxDate: null
    },
    selectedRange: { minDate: null, maxDate: null },
    granularity: {
      start: Granularity.Year,
      depth: Granularity.Year
    },
    presetDateRanges: null, // <- saves the presets
    viewBy: Granularity.Day,
    comparisonPresets: null,
  }

  public comparisonPeriod: IComparisonItem = {
    comparisonID: null, 
    name: 'None', 
    enabled: true
  }; // <- use it to save the selected comparison period
  
  public readonly session$ = this._sessionS.session$;


  constructor(
    private _keywordsFilterConfigS: KeywordsFilterConfigService,
    private _sessionS: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this._subscribeSafe(this._keywordsFilterConfigS.dateRestrictionSubject$,
     (dateRestriction: IDateRange) => {
      console.log('dateRestriction', dateRestriction);
      this._keywordsFilterConfigS.getDatePresets();
      
      
      this._subscribeSafe(this._keywordsFilterConfigS.datePresetsSubject$, data => {
        console.log('presets', data);
        if (data?.length > 0) {

          this.dateRangeObject.presetDateRanges = data;
        }
      })
      
      if (dateRestriction) { 
        this.dateRangeObject.dateRangeLimits.minDate = dateRestriction.minDate;
        this.dateRangeObject.dateRangeLimits.maxDate = dateRestriction.maxDate;
        console.log('initialDates updated');
        this.dateReady = true;
        if (!this.initialized) {
          this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({
            // testing, we need to initialize with the default min/max dates (defined in doc) if possible
            minDate: dateRestriction?.minDate,
            maxDate: dateRestriction?.maxDate
          })
          this.initialized = true;
        }
      }

      
      this._subscribeSafe(this._keywordsFilterConfigS.comparisonPresetsSubject$, data => {
        console.log('comparison', data);
        if (data?.length > 0) {

          this.dateRangeObject.comparisonPresets = data;
        }
      })
    })
  } 

  dateChanged(event): void {
    // Date().toISOString() creates the wrong date, 31/12/2023 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // Date().toLocaleDateString() creates the correct date, 01/01/2024 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // same scenario if you are in LA (UTC-8) or anywhere else in the world
    
    this.minDateSelected = event.startDate, 
    this.maxDateSelected = event.endDate
    
    // should prompt a report update
    this._keywordsFilterConfigS
      .updateFilterConfigAndGetReportData(
        {
          minDate: event.startDate, 
          maxDate: event.endDate
        }, 
        this.comparisonPeriod, // <- either null when None is selected, or a defined period
      );
    console.log('dateChanged', this.minDateSelected, this.maxDateSelected);
    this._keywordsFilterConfigS.validateComparisonPresets({minDate: event.startDate, maxDate: event.endDate});
    this.dateSelectionChanged.emit({minDate: event.startDate, maxDate: event.endDate})
  }

  onComparisonchage(period): void {
    this.comparisonPeriod = period;
    this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({minDate: this.minDateSelected, maxDate: this.maxDateSelected}, period);
  }

  hasLastMonth(): boolean {
    return false; // TESTING
  }

  navigateBack(): void {
    this.navigateBackEmitter.emit(true);
  }

  onOpen(): void {
    console.log('datepicker component opened');
    // possible place to add footer injection through javascript, as there doesn't seem to be a native way of modifying the component's footer
    
  }
}
