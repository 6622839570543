<!--Apple Actions-->
<div class="box__item box__item--editable">
    <div class="d-flex w--100">
      <!-- <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-bolt"></i></div> -->
      <div class="w--100" [class.txt--medium-gray]="isUnderReview">
        <h4 class="w--100 txt--md fw--700 mb--10">{{sectionName}}</h4>
        <div>
          <!-- conditional logic to show primary action -->
          <div class="mb--10">
            <div class="w--100 txt--md action-subtitle mb--10"><span>Primary Action</span></div>
            <span>{{primaryAction}}</span>
          </div>
          <!-- conditional logic to show other actions -->
          <div class="w--100 txt--md action-subtitle mb--10"><span>More Action</span>s</div>
          <span *ngFor="let action of additionalActions; let i = index">
            {{action + (i < additionalActions.length - 1 ? ', ' : '')}}
          </span>
        </div>
      </div>
    </div>
</div>