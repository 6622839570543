import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalCompanyNameComponent } from '../../modal-company-name/modal-company-name.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-location-name',
  templateUrl: './location-name.component.html',
  styleUrls: ['./location-name.component.scss']
})
export class LocationNameComponent implements OnInit {

  @Input() locationName: string;
  @Input() set locationAddress (address) {
    this.appleFormattedAddress = `
      ${(address?.addressLines[0] + ' ') || ''}
      ${(address?.locality + ',') || ''}
      ${(address?.administrativeArea + ' ') || ''}
      ${(address?.postalCode || '')}
    `;
  };
  @Input() verified = false;
  @Input() placeId: string;
  @Input() isEdit = true;
  @Input() isUnderReview = false;
  @Input() isApple = false;
  @Output() onUpdate =  new EventEmitter<boolean>()

  public appleFormattedAddress = '';


  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  openNameDialog(): void {
    const dialogRef = this.dialog.open(ModalCompanyNameComponent, {
      width: '680px',
      data: { name: this.locationName, placeId: this.placeId }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.onUpdate.emit(true);
    });

    dialogRef.disableClose = true;
  }

}
