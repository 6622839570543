<div class="reports-filter-wrapper filter-dashboard" [class.bg--pastel--gray]="!isReport" [class.is-impersonating]="(session$ | async).isImpersonating || (session$ | async).isTrial">
    <div class="d-flex justify-content-start ml--15 ml--290" (click)="navigateBack()" data-html2canvas-ignore>
        <button mat-button [style.color]="'#8c91a2'"
          class="btn cursor--pointer txt--blue fw--black txt--14">
          <i class="far fa-arrow-left"></i>
          Back
        </button>
      </div>
      <div class="w--100 d-flex justify-content-end" *ngIf="dateReady">
        <!-- Monthly Datepicker -->

        <div>
          <span class="label-datepicker d-inline-block txt--normal ml--10 mr--10 mb--0">Date Range</span>
          
          <ejs-daterangepicker
              class="d-inline-block"
              id="date-range-picker"
              #dateRangePickerMonthly 
              (change)="dateChanged($event)"
              [start]="dateRangeObject?.granularity?.start"
              [startDate]="dateRangeObject?.dateRangeLimits?.minDate"
              [endDate]="dateRangeObject?.dateRangeLimits?.maxDate"
              [format]="'MMM yyyy'"
              [depth]="dateRangeObject?.granularity?.depth" 
              [min]="dateRangeObject?.dateRangeLimits?.minDate"
              [max]="dateRangeObject?.dateRangeLimits?.maxDate"
              [allowEdit]="false" 
              [showClearButton]="false"
              (open)="onOpen()"
              (click)="dateRange.show()">

              <!-- Presets -->
              <e-presets>
                  <!-- Check possible ngIf cases for each preset (needs rework after IPreset rework) -->
                  <ng-container *ngFor="let preset of dateRangeObject?.presetDateRanges">
                      <e-preset 
                          [label]="preset?.name" 
                          [start]="preset?.dateRange?.minDate" 
                          [end]="preset?.dateRange?.maxDate">              
                      </e-preset>
                  </ng-container>
              </e-presets>
          </ejs-daterangepicker>
 
        </div>

        <!-- Comparion  -->
        <div>
            <span class="d-inline-block txt--normal ml--10 mr--10 mb--0">Compare to</span>

            <button mat-button [matMenuTriggerFor]="selectionComparison" class="filter__item__btn" [(ngModel)]="comparisonPeriod" ngDefaultControl>
                {{ comparisonPeriod.name }} <i class="fal fa-angle-down" data-html2canvas-ignore></i>
              </button>
              
              <mat-menu #selectionComparison="matMenu" [overlapTrigger]="false" xPosition="before">
                <ng-container *ngFor="let group of dateRangeObject?.comparisonPresets">
                  <!-- Header -->
                  <ng-container *ngIf="group.header">
                    <span class="menu-header txt--14 txt--dark--gray">{{ group.header }}</span>
                  </ng-container>
              
                  <!-- Options -->
                  <button
                    mat-button *ngFor="let option of group.items"
                    class="cursor--pointer menu-items"
                    [class.menu-items-without-header]="!group.header"
                    [class.menu-single-item]="group.items?.length === 1"
                    color="accent"
                    [class.txt-disabled]="!option.enabled"
                    [disabled]="!option.enabled"
                    (click)="onComparisonchage(option)"
                  >
                    <span class="txt--14">{{ option?.name }}</span>
                  </button>
                </ng-container>
              </mat-menu>              
              
        </div>
    </div>
</div>
