import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-apple-location-info',
  templateUrl: './apple-location-info.component.html',
  styleUrls: ['./apple-location-info.component.scss']
})
export class AppleLocationInfoComponent implements OnInit {
  public readonly locationId: any;
  public readonly accountId: any;
  public locationData: any = {};
  public regionCode: string;
  public progress = false;

  public isReport = false;
  public location$ = new BehaviorSubject(null);
  public isProgressPush = null;
  public fetchProgress = null;

  constructor(
    private _route: ActivatedRoute,
  ) {
    this.locationId = this._route.snapshot.parent.params.locationId;
    this.accountId  = this._route.snapshot.parent.params.accountId;
  }

  ngOnInit() {

    this.locationData = {
      locationName: "Barclay Prime",
      about: "Example description - A boutique steakhouse for the 21st century, Barclay Prime provides a sleek and sexy space for world-class cuisine. Taking cues from a European library, with bright banquettes, marble surfaces and crystal chandeliers, the dining room is a chic but comfortable setting perfect for any experience. And the sophistication doesn’t stop at the decor. A smart revamp of the conventional steakhouse, the menu is built around subtle twists that never skimp on luxury. ",
      address: {
        "addressLines": [
            "444 S 20th Street"
        ],
        "administrativeArea": "PA",
        "languageCode": "en",
        "locality": "Philadelphia",
        "postalCode": "19103",
        "regionCode": "US"
      },
      goodToKnow: "List of Good to Know attributes, Accepts Reservations, Accepts Cash, Accepts Debit Cards, Accepts Credit Cards, Apple Pay, Takeout, Delivery, Fine Dining, Formal",
      hours: [
        {
          "closeDay": "SUNDAY",
          "closeTime": {
            "hours": 22,
            "minutes": 0,
            "nanos": 0,
            "seconds": 0
          },
          "openDay": "SUNDAY",
          "openTime": {
            "hours": 17,
            "minutes": 0,
            "nanos": 0,
            "seconds": 0
          }
        },
        {
          "closeDay": "MONDAY",
          "closeTime": {
            "hours": 22,
            "minutes": 0,
            "nanos": 0,
            "seconds": 0
          },
          "openDay": "MONDAY",
          "openTime": {
            "hours": 17,
            "minutes": 0,
            "nanos": 0,
            "seconds": 0
          }
        }
      ],
      status: "open",
      phone: "301 5033426",
      website: "www.test.com",
      primaryCategory: {
        categoryId: "categories/gcid:restaurant",
        displayName: "Steakhouse",
        moreHoursTypes: [
          {
            displayName: "Senior hours",
            hoursTypeId: "SENIOR_HOURS",
            localizedDisplayName: "Horario para mayores"
          }
        ],
        name: "categories/gcid:steakhouse",
        serviceTypes: []
      },
      additionalCategories: [
        {
          categoryId: "categories/gcid:restaurant",
          displayName: "Restaurant",
          moreHoursTypes: [
              {
                  displayName: "Senior hours",
                  hoursTypeId: "SENIOR_HOURS",
                  localizedDisplayName: "Horario para mayores"
              }
          ],
          name: "categories/gcid:restaurant",
          serviceTypes: []
        },
        {
          categoryId: "categories/gcid:marketing_agency",
          displayName: "Additional categories",
          moreHoursTypes: [
              {
                  displayName: "Senior hours",
                  hoursTypeId: "SENIOR_HOURS",
                  localizedDisplayName: "Horario para mayores"
              }
          ],
          name: "categories/gcid:marketing_agency",
          serviceTypes: [
            {
              displayName: "Branding",
              serviceTypeId: "job_type_id:branding"
            }
          ]
        },
      ],
      actions: [
        { description: "Reserve", primary: true },
        { description: "Example actions", primary: false },
        { description: "Order", primary: false },
        { description: "Menu", primary: false }
      ],
      brand: {
        name: "Barclay Prime (US)",
        logo: "",
        verified: true
      }
    }
  }

  dispatchAction(event): void {
    return;
  }

  updateLocation(event): void {
  }

}
