import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalActionLinksComponent } from '../../modal-action-links/modal-action-links.component';
var LocationActionLinksComponent = /** @class */ (function () {
    function LocationActionLinksComponent(_dialogS) {
        this._dialogS = _dialogS;
        this.actionLinks = [];
        this.isEdit = true;
        this.isUnderReview = false;
        this.isApple = false;
        this.onUpdate = new EventEmitter();
    }
    LocationActionLinksComponent.prototype.ngOnInit = function () { };
    LocationActionLinksComponent.prototype.openActionLinksDialog = function (element) {
        var _this = this;
        var _a;
        var dialogRef = this._dialogS.open(ModalActionLinksComponent, {
            width: '680px',
            data: {
                title: (_a = element) === null || _a === void 0 ? void 0 : _a.displayName,
                actionLinks: element
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.onUpdate.emit(true);
            }
        });
    };
    return LocationActionLinksComponent;
}());
export { LocationActionLinksComponent };
