import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-apple-performance-insights',
  templateUrl: './apple-performance-insights.component.html',
  styleUrls: ['./apple-performance-insights.component.scss']
})
export class ApplePerformanceInsightsComponent implements OnInit {

  public dataPicker = null;
  public isProgress = false;
  public isReport = false;
  public stats = [];
  public locations = null;

  public reportName = null;
  public selectDate = null;
  public shared = null;
  public checks = null;
  public typeReport = null;
  public report = null;

  public aggregation: any;
  public lockDates: any;
  public isShared: any;
  public accountId: any;
  public hasFoodOrdering: any;
  public minDate: any;
  public maxDate: any;
  public multiChartSelected: any;
  public subscribeToDatepicker: any;
  public showTooltipsOnDisabledDates: any;

  constructor() { }

  ngOnInit() {
    // Mocked Data
    this.stats.push({
      CALL: {
        data: [36,25,21,18,13,13,22,18,22,15,15,21,36],
        id: 1,
        label: "Calls",
        total: {
            numeric: 275,
            percent: 19
        }
      },
      DIRECTIONS: {
        data: [66,48,58,47,38,58,41,36,44,57,39,44,63],
        id: 2,
        label: "Direction",
        total: {
            numeric: 639,
            percent: 45
        }
      },
      WEBSITES: {
        data: [47,28,40,32,39,24,36,35,44,34,49,55,53],
        id: 3,
        label: "Website",
        total: {
            numeric: 516,
            percent: 36
        }
      },
      title: "Primary Actions",
      totals: 17918,
      labels: ["Dec '23","Jan '24","Feb '24","Mar '24","Apr '24","May '24","Jun '24","Jul '24","Aug '24","Sep '24","Oct '24","Nov '24","Dec '24"]
    })

    this.stats.push({
      SHARE: {
        data: [66,48,58,47,38,58,41,36,44,57,39,44,63],
        id: 4,
        label: "Share",
        total: {
          numeric: 275,
          percent: 34
        }
      },
      PHOTOS: {
        data: [36,25,21,18,13,13,22,18,22,15,15,21,36],
        id: 5,
        label: "Photos",
        total: {
            numeric: 639,
            percent: 18
        }
      },
      SHOWCASE: {
        data: [47,28,40,32,39,24,36,35,44,34,49,55,53],
        id: 6,
        label: "Showcase",
        total: {
            numeric: 516,
            percent: 45
        }
      },
      TEST4: {
        data: [51,70,65,54,44,52,63,48,36,29,45,56,66],
        id: 7,
        label: "Other",
        total: {
            numeric: 516,
            percent: 72
        }
      },
      title: "Secondary Actions",
      totals: 102565,
      labels: ["Dec '23","Jan '24","Feb '24","Mar '24","Apr '24","May '24","Jun '24","Jul '24","Aug '24","Sep '24","Oct '24","Nov '24","Dec '24"]
    })
    
    this.stats.push({
      YOURCARD: {
        data: [28,31,37,52,58,56,59,60,63,66,67,72,76],
        id: 8,
        label: "Your place card",
        total: {
          numeric: 275,
          percent: 34
        }
      },
      LOCATIONSCARDS: {
        data: [36,25,21,18,13,13,22,18,22,15,15,21,36],
        id: 9,
        label: "Place cards from locations like yours",
        total: {
            numeric: 639,
            percent: 18
        }
      },
      title: "Views of Your Place Card",
      totals: 6072,
      labels: ["Dec '23","Jan '24","Feb '24","Mar '24","Apr '24","May '24","Jun '24","Jul '24","Aug '24","Sep '24","Oct '24","Nov '24","Dec '24"]
    })

    // Mocked Data
    this.dataPicker = {
      aggregation: "Month",
      range: {
          start: "2023-12-01",
          end: "2024-12-31"
      },
      locations: [
          {
              accountId: "104870730429202066229",
              locationId: "2172917685735274075"
          }
      ]
    }

    // Mocked Data
    this.locations = [
      {
        accountId: "104870730429202066229",
        locationId: "2172917685735274075"
      }
    ]

    // Mocked Data
    this.aggregation = null;
    this.lockDates = false;
    this.isShared = false;
    this.accountId = "104870730429202066229";
    this.hasFoodOrdering = false;
    this.minDate = moment("2021-11-20T02:59:59.000Z");
    this.maxDate = moment("2025-01-24T02:59:59.000Z");
    this.multiChartSelected = null;
    this.subscribeToDatepicker = true;
    this.showTooltipsOnDisabledDates = true;
  }

}
