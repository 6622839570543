<div class="row box--height">
  <!--Actions on your Google Business Profile & total Photo Views on your Google Business Profile-->
  <div class="col-12 mb--30">
    <div class="box box--rounded box--shadow bg--white" [class.no-shadow]="hasMultipleChart">
      <div class="align-items-center justify-content-start p--20" *ngIf='titleIcon && !hasMultipleChart'>
        <h2 class="txt--md m--0 mb--15"><strong>{{stats?.title}}</strong></h2>
        <p class="d--inl-block m--0 txt--price fw--regular">{{stats?.totals | number: '1.0-0'}}</p>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row {{ type == 'vertical' ? 'm--0' : '' }}">
          <ng-container *ngIf="type!='vertical'; else vertical">
            <div [class.col-9]="showRightProgressBar" [class.apple-chart]="isApple">
              <p *ngIf="hasMultipleChart" class="txt--sm txt--dark--gray txt--center">{{stats?.title}}</p>
              <div>
                <app-chart-line
                *ngIf='line && labels'
                [title]="stats?.title"
                [commaNumbers]="true"
                [noDecimals]="true"
                [colors]="colors"
                [dataset]="dataset"
                [labels]="labels"
                [aggregation]="dataPicker.aggregation"
                [centerLegend]="centerLegend"
                ></app-chart-line>
              </div>
            </div>

            <ng-container *ngIf="stats.title !='Photo views' && stats.title !='Photo quantity' && !showRightProgressBar">
              <div class="col-md-5" *ngIf="hasDonutGraph">
                <app-chart-donut *ngIf="dataset?.length > 1" [dataset]="dataset" [colorChange]="type"></app-chart-donut>
                <div class="col-lg-12 txt--center">
                  <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">{{stats?.totals |  number: '1.0-0' }}</p>
                  <p class="txt--md txt--lightgray fw--medium">Total</p>
                </div>
              </div>
              
              <div class="w--100 p--lr-15" [class.col-md-7]="hasDonutGraph" [class.apple-container]="isApple"  *ngIf="stats.title !='Photo views' && stats.title !='Photo quantity' && dataset.length > 1">
                <app-chart-progress 
                *ngFor="let data of dataset; let i = index"
                [total]="data?.total" 
                [label]="data?.label" 
                [bgColor]="data?.borderColor" 
                [index]="i"
                ></app-chart-progress>
              </div>
            </ng-container>

            <div *ngIf="showRightProgressBar" class="col-3">
              <app-chart-progress
                class="d--block"
                *ngFor="let data of dataset; let i = index"
                [total]="data?.total"
                [label]="data?.label"
                [bgColor]="data?.borderColor" 
                [index]="i"
                [showRightProgressLabel]="showRightProgressBar"
              ></app-chart-progress>
            </div>

          </ng-container>

          <ng-template #vertical>

            <div class="col-6" *ngFor="let data of dataset">
              <app-chart-progress [total]="data?.total" [label]="data?.label" [bgColor]="data?.borderColor"
                                  type="revenue"></app-chart-progress>
            </div>
            <div class="col-12 txt--center">
              <p class="txt--xxxl-sm txt--lightblue fw--medium m--0">{{stats?.totals |  number: '1.0-0' }}</p>
              <p class="txt--md txt--lightgray fw--medium">Total</p>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>
