/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-name.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./location-name.component";
import * as i8 from "@angular/material/dialog";
var styles_LocationNameComponent = [i0.styles];
var RenderType_LocationNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationNameComponent, data: {} });
export { RenderType_LocationNameComponent as RenderType_LocationNameComponent };
function View_LocationNameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "under-review"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Under review"]))], null, null); }
function View_LocationNameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "txt--lg fw--medium m--0"]], [[2, "txt--medium-gray", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "outerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationNameComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isUnderReview; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUnderReview; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.locationName; _ck(_v, 2, 0, currVal_1); }); }
function View_LocationNameComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "selected-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-badge-check mr--5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verified"]))], null, null); }
function View_LocationNameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-flex flex-column w--100 gap--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "txt--lg fw--medium m--0"]], [[2, "txt--medium-gray", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationNameComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.verified; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUnderReview; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.locationName; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.appleFormattedAddress; _ck(_v, 5, 0, currVal_2); }); }
function View_LocationNameComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-icon"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNameDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fas fa-pencil m--0"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LocationNameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "box__item box__item--sm box__item--editable pb--20"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationNameComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["apple", 2]], null, 0, null, View_LocationNameComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationNameComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isApple; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isEdit; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LocationNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-name", [], null, null, null, View_LocationNameComponent_0, RenderType_LocationNameComponent)), i1.ɵdid(1, 114688, null, 0, i7.LocationNameComponent, [i8.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationNameComponentNgFactory = i1.ɵccf("app-location-name", i7.LocationNameComponent, View_LocationNameComponent_Host_0, { locationName: "locationName", locationAddress: "locationAddress", verified: "verified", placeId: "placeId", isEdit: "isEdit", isUnderReview: "isUnderReview", isApple: "isApple" }, { onUpdate: "onUpdate" }, []);
export { LocationNameComponentNgFactory as LocationNameComponentNgFactory };
