import { OnInit, EventEmitter } from '@angular/core';
import { ModalCompanyNameComponent } from '../../modal-company-name/modal-company-name.component';
import { MatDialog } from '@angular/material/dialog';
var LocationNameComponent = /** @class */ (function () {
    function LocationNameComponent(dialog) {
        this.dialog = dialog;
        this.verified = false;
        this.isEdit = true;
        this.isUnderReview = false;
        this.isApple = false;
        this.onUpdate = new EventEmitter();
        this.appleFormattedAddress = '';
    }
    Object.defineProperty(LocationNameComponent.prototype, "locationAddress", {
        set: function (address) {
            var _a, _b, _c, _d;
            this.appleFormattedAddress = "\n      " + ((((_a = address) === null || _a === void 0 ? void 0 : _a.addressLines[0]) + ' ') || '') + "\n      " + ((((_b = address) === null || _b === void 0 ? void 0 : _b.locality) + ',') || '') + "\n      " + ((((_c = address) === null || _c === void 0 ? void 0 : _c.administrativeArea) + ' ') || '') + "\n      " + (((_d = address) === null || _d === void 0 ? void 0 : _d.postalCode) || '') + "\n    ";
        },
        enumerable: true,
        configurable: true
    });
    ;
    LocationNameComponent.prototype.ngOnInit = function () {
    };
    LocationNameComponent.prototype.openNameDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCompanyNameComponent, {
            width: '680px',
            data: { name: this.locationName, placeId: this.placeId }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res != '')
                _this.onUpdate.emit(true);
        });
        dialogRef.disableClose = true;
    };
    return LocationNameComponent;
}());
export { LocationNameComponent };
