
<div id="dashboard-filter" class="d-flex bg--pastel--gray filter-dashboard" [class.is-impersonating]="(session$ | async).isImpersonating"  *ngIf="!progress"
[style.top]="(session$ | async).isTrial || topBanner ? '101px' : '64px'">
    <!--Go back dropdown -->
    <div class="d-flex justify-content-start" *ngIf="!isShared && accountId">
        <button mat-button class="btn cursor--pointer txt--blue fw--black txt--14" (click)="GoBack()" data-html2canvas-ignore>
            <i class="far fa-arrow-left"></i>
            Back
        </button>
    </div>

    <div class="d-flex flex-grow-1 justify-content-end">

        <ng-container [ngSwitch]="typeDashboard">
            <ng-container *ngSwitchCase="'locInfo'">
                <!--Lock location button-->
                <div class="filter__item"  data-html2canvas-ignore gmbLGF *ngIf="location && typeDashboard === 'locInfo'">
                    <div matTooltip="To lock a location, it must not be permanently closed."
                        [matTooltipDisabled]="location?.location?.openInfo?.status !== 'CLOSED_PERMANENTLY'"
                    >
                        <button
                            gmbLGF
                            *ngIf="location?.lockedOn === null"
                            mat-flat-button
                            color="accent"
                            class="btn btn--action fw--regular"
                            (click)="launcAction('lock')"
                            [disabled]="location?.location?.openInfo?.status === 'CLOSED_PERMANENTLY'"
                            >
                            <i class="fas fa-unlock"></i> Lock Data
                        </button>
                    </div>
                    <button
                        *ngIf="location?.lockedOn !== null"
                        mat-flat-button
                        color="accent"
                        class="btn btn--action fw--regular"
                        (click)="launcAction('unlock')"
                    >
                        <i class="fas fa-lock"></i> Unlock Data
                    </button>
                </div>


                <!--Push to google button-->
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-button
                            (click)="launcAction('pushGoogle')"
                            class="btn btn--action min--width--0 cursor--pointer txt--blue fw--black txt--14"
                            [disabled]="isProgressPush"
                        >
                            Push <i *ngIf="isProgressPush" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>

                <!--Fetch to google button-->
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-button
                            (click)="launcAction('fetchDifference')"
                            class="btn btn--action min--width--0 mr--10 cursor--pointer txt--blue fw--black txt--14"
                            [disabled]="isProgressFetch"
                        >
                             Fetch <i *ngIf="isProgressFetch" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'services'">
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="launcAction('updateService')"
                            class="btn btn--action fw--regular"
                            [disabled]="isProgressSave"
                        >
                            Push <i *ngIf="isProgressSave" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>

                <!--Fetch to google button-->
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="updateServices()"
                            class="btn btn--action fw--regular"
                            [disabled]="isProgressFetch"
                        >
                            Fetch <i *ngIf="updateService" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'menu'">
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="launcAction('updateMenu')"
                            class="btn btn--action fw--regular"
                            [disabled]="isProgressSave"
                        >
                            Push <i *ngIf="isProgressSave" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>

                <!--Fetch to google button-->
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="updateMenu()"
                            class="btn btn--action fw--regular"
                            [disabled]="updateService"
                        >
                            Fetch <i *ngIf="updateService" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'posts'">
                <div class="filter__item">
                    <span class="alternate-theme">
                        <button
                            mat-flat-button
                            color="primary"
                            class="btn btn--action fw--regular"
                            (click)="purgeButtonPressed()"
                        >
                            Purge
                            <i *ngIf="isPurging" class="fas fa-spinner fa-pulse txt--blue"></i>
                        </button>
                    </span>
                </div>
                <div class="filter__item hidden-content">
                    <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" (click)="refreshButtonPressed()">
                        <i class="fas fa-redo" [ngClass]="{'fa-spin': isRefreshing}"></i>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
            </ng-container>
        </ng-container>
    </div>
  </div>
