<!--Special Hours-->
<div class="box__item box__item--editable lign-items-start" [class.pb--0]="isUnderReview">
  <div class="d-flex w--100" [ngClass]="specialHourPeriods ? 'align-items-start' : 'align-items-center'">
    <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-calendar-day"></i></div>
    <div class="w--100" [class.txt--medium-gray]="isUnderReview">
      <h4 class="w--100 txt--md fw--700 mb--10">Special Hours</h4>
      <p class="w--100" *ngIf="!hasSpecialHours">Add Special Hours</p>
      <div *ngIf="hasSpecialHours" class="w--100 row">
        <ng-container *ngFor="let day of specialHourPeriods | keyvalue: orderPeriods">
          <ng-container *ngFor="let hours of day?.value; let i = index;">
            <div class="col-4">
              <p *ngIf="i === 0" class="txt--uppercase txt--md">{{ formatDate(day.key) }}</p>
            </div>

            <div class="col-8">
              <p class="txt--md">
                <span *ngIf="hours.isClosed;else time"> Closed</span>
                <ng-template #time>
                  {{hours.openTime|hours12}} - {{hours.closeTime|hours12}}
                </ng-template>
              </p>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <button mat-button (click)="openSpecialHoursDialog()" [disabled]="countRegularHours == 0" class="btn btn-icon btn-icon--gray" *ngIf="isEdit">
    <em class="fas fa-pencil" [matTooltipDisabled]="countRegularHours > 0" matTooltip="You must add regular hours before you can add special hours. " matTooltipPosition="below" matTooltipClass="tooltip tooltip--black"></em>
  </button>
</div>
<div *ngIf="isUnderReview" class="txt-pendingMask">
  <span class="under-review" role="alert">Under review</span>
</div>
