/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dashboard-filter/dashboard-filter.component.ngfactory";
import * as i3 from "../dashboard-filter/dashboard-filter.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/location.service";
import * as i6 from "../../services/modal.service";
import * as i7 from "../../services/session.service";
import * as i8 from "../dashboard-location/menu-location/menu-location.component.ngfactory";
import * as i9 from "../dashboard-location/menu-location/menu-location.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../services/google.service";
import * as i12 from "../../services/snackbar.service";
import * as i13 from "./dashboard-menu.component";
var styles_DashboardMenuComponent = [i0.styles];
var RenderType_DashboardMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardMenuComponent, data: {} });
export { RenderType_DashboardMenuComponent as RenderType_DashboardMenuComponent };
export function View_DashboardMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { menuComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dashboard-filter", [["typeDashboard", "menu"]], null, [[null, "ClickEvent"], [null, "UpdateMenuList"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ClickEvent" === en)) {
        var pd_0 = (_co.dispatchAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("UpdateMenuList" === en)) {
        var pd_1 = (_co.updateMenu($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DashboardFilterComponent_0, i2.RenderType_DashboardFilterComponent)), i1.ɵdid(2, 114688, null, 0, i3.DashboardFilterComponent, [i4.Router, i4.ActivatedRoute, i5.LocationService, i6.ModalService, i7.SessionService], { typeDashboard: [0, "typeDashboard"], accountId: [1, "accountId"], progress: [2, "progress"], locationId: [3, "locationId"], isProgressSave: [4, "isProgressSave"] }, { ClickEvent: "ClickEvent", UpdateMenuList: "UpdateMenuList" }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "container--full view-without-padding mt--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "content__body"]], [[4, "height", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-menu-location", [["type", "menu"]], null, null, null, i8.View_MenuLocationComponent_0, i8.RenderType_MenuLocationComponent)), i1.ɵdid(6, 114688, [[1, 4], ["MenuLocation", 4]], 0, i9.MenuLocationComponent, [i10.MatDialog, i11.GoogleService, i12.SnackbarService], { foodMenuList: [0, "foodMenuList"], type: [1, "type"], accountId: [2, "accountId"], locationId: [3, "locationId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu"; var currVal_1 = _co.accountId; var currVal_2 = _co.progress; var currVal_3 = _co.locationId; var currVal_4 = _co.isUpdatedMenu; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.foodMenu; var currVal_7 = "menu"; var currVal_8 = _co.accountId; var currVal_9 = _co.locationId; _ck(_v, 6, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_5 = "100%"; _ck(_v, 4, 0, currVal_5); }); }
export function View_DashboardMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-menu", [], null, null, null, View_DashboardMenuComponent_0, RenderType_DashboardMenuComponent)), i1.ɵdid(1, 114688, null, 0, i13.DashboardMenuComponent, [i4.ActivatedRoute, i7.SessionService, i12.SnackbarService, i11.GoogleService, i5.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardMenuComponentNgFactory = i1.ɵccf("app-dashboard-menu", i13.DashboardMenuComponent, View_DashboardMenuComponent_Host_0, {}, {}, []);
export { DashboardMenuComponentNgFactory as DashboardMenuComponentNgFactory };
