/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-photos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dashboard-filter/dashboard-filter.component.ngfactory";
import * as i3 from "../dashboard-filter/dashboard-filter.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/location.service";
import * as i6 from "../../services/modal.service";
import * as i7 from "../../services/session.service";
import * as i8 from "../dashboard-location/photos-location/photos-location.component.ngfactory";
import * as i9 from "../dashboard-location/photos-location/photos-location.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../services/google.service";
import * as i12 from "../../services/storage-image.service";
import * as i13 from "../../services/snackbar.service";
import * as i14 from "./dashboard-photos.component";
var styles_DashboardPhotosComponent = [i0.styles];
var RenderType_DashboardPhotosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPhotosComponent, data: {} });
export { RenderType_DashboardPhotosComponent as RenderType_DashboardPhotosComponent };
export function View_DashboardPhotosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-filter", [["typeDashboard", "photos"]], null, null, null, i2.View_DashboardFilterComponent_0, i2.RenderType_DashboardFilterComponent)), i1.ɵdid(1, 114688, null, 0, i3.DashboardFilterComponent, [i4.Router, i4.ActivatedRoute, i5.LocationService, i6.ModalService, i7.SessionService], { typeDashboard: [0, "typeDashboard"], accountId: [1, "accountId"], progress: [2, "progress"], locationId: [3, "locationId"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "container--full view-without-padding mt--0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "content__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "table--shadow mb--30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "mat-row mat-row--padding mat-row--border justify-content-between align-items-center bg--white"], ["style", "padding: 20px 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-photos-location", [], null, null, null, i8.View_PhotosLocationComponent_0, i8.RenderType_PhotosLocationComponent)), i1.ɵdid(7, 245760, null, 0, i9.PhotosLocationComponent, [i10.MatDialog, i11.GoogleService, i12.StorageImageService, i13.SnackbarService, i4.ActivatedRoute, i7.SessionService, i5.LocationService], { accountId: [0, "accountId"], locationId: [1, "locationId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "photos"; var currVal_1 = _co.accountId; var currVal_2 = false; var currVal_3 = _co.locationId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.accountId; var currVal_5 = _co.locationId; _ck(_v, 7, 0, currVal_4, currVal_5); }, null); }
export function View_DashboardPhotosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-photos", [], null, null, null, View_DashboardPhotosComponent_0, RenderType_DashboardPhotosComponent)), i1.ɵdid(1, 114688, null, 0, i14.DashboardPhotosComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPhotosComponentNgFactory = i1.ɵccf("app-dashboard-photos", i14.DashboardPhotosComponent, View_DashboardPhotosComponent_Host_0, {}, {}, []);
export { DashboardPhotosComponentNgFactory as DashboardPhotosComponentNgFactory };
