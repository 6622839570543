// dep
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material';

// app
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from '../../services/spinner.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls:  ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent extends BaseComponent implements OnInit {
  isFullWidth = false;
  contentClass: any;
  opened = false;

  @ViewChild('sidenav', {static: false}) 
  sidenav: MatSidenav

  session$ = this._sessionS.session$; 
  
  constructor(
    public spinnerService: SpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private _sessionS : SessionService,
    private renderer2: Renderer2,
    private dataTransfer: DataTransferService<string>
  ) {
    super();
    const appTitle = this.titleService.getTitle();
    this._subscribeSafe(router.events,
      (event) => {
        if(!(event instanceof NavigationEnd))
          return;
        const title = this.activatedRoute.firstChild.snapshot.data.title || appTitle;
        // FIXME: Multiple subscriptions
        this._subscribeSafe(this.dataTransfer.getData(),
          locationName => {
            const {company_name} = this._sessionS.getDomain().branding
            this.titleService.setTitle(`${title} | ${locationName} | ${company_name}`)
          });

        this._updateClass();
    });

    this.opened = true;
  }

  toggleSideNav() : void {
    this.sidenav.toggle();
    const elementId = document.getElementById('dashboard-filter');
    if (elementId) {
      if (!this.sidenav.opened) {
        this.renderer2.addClass(elementId, 'remove-padding')
      } else {
        this.renderer2.removeClass(elementId, 'remove-padding')
      }
    }
  }

  ngOnInit() : void {
    this._updateClass();
  }

  private _updateClass() : void {
    const url = this.router.url;
    const path = this.activatedRoute.routeConfig?.path;
    const islocationView = path === 'account/:accountId/location/:locationId'

    this.isFullWidth = (url.includes('insights') || url.includes('reviews') || islocationView);
    this.contentClass = {
      'content content--padding': !this.isFullWidth,
      'dashboard-content': this.isFullWidth
    };
  }
  
}

