import { __read, __values } from "tslib";
// dep
import { EventEmitter, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import * as moment from 'moment-timezone';
import { DATE_PICKER_RANGES } from '../../constants/datePicker';
import { EventMessageService } from "../../services/event-message.service";
import { EventNames } from 'src/app/constants/event-names';
import { ReportService } from 'src/app/services/report.service';
import { DatesService } from 'src/app/services/dates.service';
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(em, reportS, renderer, el, dateS) {
        this.em = em;
        this.reportS = reportS;
        this.renderer = renderer;
        this.el = el;
        this.dateS = dateS;
        this.id = '';
        this.dateSelected = new EventEmitter();
        this.subscribeToDatepicker = false;
        this.showTooltipsOnDisabledDates = false;
        this.isReadOnly = false;
        this.hasDateLimitsInfo = false;
        this.isAppleMockup = false;
        this.ranges = DATE_PICKER_RANGES;
        this.opens = 'left';
        this.drops = 'down';
        this.alwaysShowCalendars = true;
        // keepCalendarOpeningWithRange = true;
        this.locale = {
            applyLabel: 'Apply',
            format: 'MM/DD/YYYY',
            cancelLabel: 'Cancel',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(),
            monthNames: moment.monthsShort(),
            firstDay: 0,
        };
        this.isClosed = true;
        this.events = [];
        this.tooltipsApplied = false;
        this.isOpened = false;
    }
    DatePickerComponent.prototype.ngOnInit = function () {
        var _a;
        console.log('mock data in date-picker.component, remove data and Input()');
        if (this.isAppleMockup) {
            this.select = {
                start: moment("2024-08-17T02:59:59.000Z"),
                end: moment("2025-01-01T02:59:59.999Z")
            };
        }
        var now = moment();
        if (this.rangeB) {
            this.id = 'B';
        }
        this.drops = this.dropsChange || 'down';
        if (this.minRange) {
            var months = this.reportType == 'performance-rollup' || this.reportType == 'review' ? 13 : 12;
            var minDate = now.clone().subtract(moment.duration({ 'months': months }));
            this.minDateRange = ((_a = this.minDateRange) === null || _a === void 0 ? void 0 : _a.isAfter(minDate)) ? this.minDateRange : minDate;
        }
        if (this.disabled)
            this.dateSelected.emit(this.dataPicker);
        this.setRanges();
    };
    DatePickerComponent.prototype.applyTooltips = function () {
        var _a, _b;
        if (this.tooltipsApplied)
            return;
        var elementsWithTooltip = document.querySelectorAll('.off.disabled');
        if (((_a = elementsWithTooltip) === null || _a === void 0 ? void 0 : _a.length) && this.showTooltipsOnDisabledDates) {
            var date_1 = (_b = this.maxDate) === null || _b === void 0 ? void 0 : _b.set({ 'hours': 23, 'minute': 59, 'second': 59 });
            elementsWithTooltip.forEach(function (element) {
                element.setAttribute('title', ("Google Business Profile performance data is latent for up to 7 days from today's date. " +
                    ("Please choose a date before " + date_1.format('MM/DD/YYYY') + " to see the most recent ") +
                    "performance data for your business profile."));
            });
            this.tooltipsApplied = true;
        }
    };
    DatePickerComponent.prototype.addDateLimitsInfo = function () {
        var element = document.getElementsByClassName('limits-info-txt')[0];
        var buttonsInput = this.el.nativeElement.querySelector('.buttons_input');
        if (element)
            this.renderer.removeChild(element.parentNode, element);
        if (this.minDateRange && this.maxDate && buttonsInput) {
            var minDate = this.dateS.getStringDate(this.minDateRange) || '';
            var maxDate = this.dateS.getStringDate(this.maxDate) || '';
            var txt = this.renderer.createText("Please select a date from " + minDate + " to " + maxDate);
            var span = this.renderer.createElement('span');
            this.renderer.addClass(span, 'limits-info-txt');
            this.renderer.appendChild(span, txt);
            this.renderer.insertBefore(buttonsInput, span, buttonsInput.children[1]);
        }
    };
    DatePickerComponent.prototype.ngAfterViewInit = function () {
        this.applyTooltips();
    };
    DatePickerComponent.prototype.rangeClicked = function (range) {
        var r = { start: range.startDate,
            end: range.endDate };
        if (this.rangeB) {
            this.dataPicker.rangeB = r;
        }
        else {
            this.dataPicker.range = r;
        }
    };
    DatePickerComponent.prototype.handleClick = function ($event) {
        var _this = this;
        this.isClosed = false;
        if (this.hasDateLimitsInfo) {
            this.addDateLimitsInfo();
        }
        if (this.minRange) {
            this.em.emitMessageEvent(EventNames.EVENT_DATEPICKER_SUBSCRIPTION, true);
        }
        if (!this.minRange && this.subscribeToDatepicker) {
            this.em.emitMessageEvent(EventNames.EVENT_DATEPICKER_FIRST_TIME_SUBSCRIPTION, true);
        }
        if (!this.isOpened) {
            this.isOpened = true;
            this.setRanges();
            if (!this.tooltipsApplied) {
                setTimeout(function () {
                    _this.applyTooltips();
                }, 500);
            }
        }
    };
    DatePickerComponent.prototype.setRanges = function () {
        var e_1, _a;
        if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
            var r = {};
            try {
                for (var _b = __values([['Last 7 Days', 'Last7'],
                    ['Last 30 Days', 'Last30'],
                    ['Last 90 Days', 'Last90'],
                    ['This Month', 'ThisMonth'],
                    ['Last Month', 'LastMonth'],
                    ['Last Year', 'LastYear'],
                    ['YTD', 'YTD']]), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), desc = _d[0], selector = _d[1];
                    var dates = this.reportS.getDates(selector, 'performance-rollup');
                    r[desc] = [dates.startDatetime,
                        dates.endDatetime];
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.ranges = r;
        }
    };
    DatePickerComponent.prototype.datesUpdated = function ($event) {
        if ($event.startDate) {
            var r = {
                start: $event.startDate.utc(true).toISOString(),
                end: $event.endDate.utc(true).toISOString()
            };
            if (this.rangeB) {
                this.dataPicker.rangeB = r;
            }
            else {
                this.dataPicker.range = r;
            }
        }
        this.dateSelected.emit(this.dataPicker);
    };
    DatePickerComponent.prototype.datesChange = function ($event) {
        if ($event.start) {
            var r = {
                start: $event.start.utc(true).toISOString(),
                end: $event.end.utc(true).toISOString()
            };
            if (this.rangeB)
                this.dataPicker.rangeB = r;
            else
                this.dataPicker.range = r;
        }
        this.dateSelected.emit(this.dataPicker);
    };
    DatePickerComponent.prototype.changeDateWrite = function (date, $event) {
        if ($event.keyCode !== 13)
            return;
        var arraydate = date.split('-');
        if (arraydate.length > 1) {
            var dateStart = moment(arraydate[0].trim());
            var dateEnd = moment(arraydate[1].trim());
            if (!dateStart && !dateEnd)
                return;
            var start = !this.minDateRange ? dateStart : (dateStart.isAfter(this.minDateRange) ? dateStart : this.minDateRange);
            var end = !this.maxDate ? dateEnd : (dateEnd.isBefore(this.maxDate) ? dateEnd : this.maxDate);
            this.select = {
                start: start || dateStart,
                end: end || dateEnd
            };
            this.datesUpdated({
                startDate: start || dateStart,
                endDate: end || dateEnd
            });
        }
    };
    DatePickerComponent.prototype.getDates = function (d1, d2) {
        d1 = moment(d1).utc().format(this.locale.format);
        d2 = moment(d2).utc().format(this.locale.format);
        return d1 + " - " + d2;
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
