<!-- TODO: Unused, remove. 
 <div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="fetchProgress">
	<mat-spinner></mat-spinner>
</div>
--> 

<mat-toolbar class="header d-flex justify-content-end bg--white p--0 {{ !noToggle ? 'header--fixed' : ''}}" style="z-index: 1000;">

	<div class="header__aside d-flex justify-content-between align-items-center {{ !noToggle ? 'header--fixed' : ''}}"
		 [ngStyle]="{'background-color': ((domain$ | async).branding?.loginColor || 
										  (domain$ | async).branding?.secondaryColor)}">
		<div class="logo">
			<a mat-button (click)="goToProfiles()">
				<img alt="Logo"
					 src="{{ (domain$ | async).branding?.mainLogo || '/assets/images/logo/blankmap.png' }}"
					 class="logo" height="50"/>
			</a>
		</div>
		<ng-container *ngIf="!noToggle && !isShared">
			<div class="menu-trigger">
				<button mat-icon-button (click)="onToggleSidenav()">
					<img src="/assets/images/hamburger.png" class="img--xxs">
				</button>
			</div>
		</ng-container>
	</div>

	<div *ngIf="!isReport && !!(session$ | async)" class="header__content d-flex justify-content-between">

		<!--search form-->
		<form class="search">
			<div class="input-field input-field--search input-field--header" *ngIf="!(session$ | async).isMember">
				<input type="text" id="searchHeader" matInput placeholder="Search By Name, Address or GBP Label" (input)="handleChangeSearch($event)"
					   [matAutocomplete]="auto"
					   [formControl]="selectedSearch">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)="selected()"
								  class="mat-autocomplete-panel--dimensions-global-search">
					<mat-option *ngFor="let option of results" [value]="option" class="search-result box">
						<div class="d-flex box__item box__item--md border-bottom">
							<div class="profile__img mr--10">
                				<span class="circle circle--lg circle--green" *ngIf="!option?.profilePhotoUrl">{{option?.accountName | slice:0:1 }} </span>
								<img default="/assets/images/unknown-avatar.png"
									 src="{{ option?.profilePhotoUrl }}"
									 alt="{{option?.accountName}}"
									 width="40"
									 height="40"
									 *ngIf="option?.profilePhotoUrl"/>
							</div>
							<div>
								<p class="txt--black m--0" *ngIf="option?.accountName">
									<strong>{{option?.accountName}}</strong></p>
								<p class="txt--lightgray m--0" *ngIf="option?.locationName">{{option?.locationName}}</p>
								<gmb-address class="txt--lightgray m--0"
									[address]='option?.address'
									[serviceAreas]="option?.serviceArea?.places?.placeInfos"
									*ngIf="option?.locationAdress || option?.serviceArea">
								</gmb-address>
								<p class="txt--black m--0" *ngIf="option?.reportType">
									<strong>{{getReportTypeString(option?.reportType)}} Report</strong></p>
								<p class="txt--lightgray m--0" *ngIf="option?.reportName">{{option?.reportName}}
									<!-- <p class="txt&#45;&#45;lightgray m&#45;&#45;0" *ngIf="option?.reportName"> Report name: {{option?.reportName}}</p>-->
							</div>
							<p class="txt--absolute txt--xs txt--uppercase txt--lightergray">{{option?.type}}</p>
						</div>
					</mat-option>
				</mat-autocomplete>
			</div>


		</form>


		<!--menu-->
		<div class="menu d-flex">
			<!--REPORTS-->
			<div class="menu__item">
				<button mat-button gmbBulkUpdateAddOn id="reportsBtn" *ngIf="!(session$ | async).bulkActionsEnabled; else normalRollUpStructure">
					<i class="fas fa-analytics mr--8 txt--14"></i>
					<span class="menu__item__disabled">Roll-up Reports</span>
					<span class="addon-mesage">Add-On</span>
				</button>

				<ng-template #normalRollUpStructure>
					<button mat-button [matMenuTriggerFor]="reportsmenu" id="rollUpReportsBtn" #normalRollUpStructure>
						<i class="fas fa-analytics mr--8 txt--14"></i>
						Roll-Up Reports 
					</button>
				</ng-template>
				<mat-menu class="mat-menu--lg mat-menu--340MaxHeight" #reportsmenu="matMenu" [overlapTrigger]="false" xPosition="before">
					<button mat-button color="primary" [routerLink]="['/performance-rollup-report']" id="rollUpReportsBtn">
						<i class="fas fa-chart-bar mr--8 txt--14"></i>
						Performance Roll-up Reports
					</button>
					<button mat-button color="primary" [routerLink]="['/performance-comparison-report']" id="comparisonReportsBtn">
						<i class="fas fa-chart-line mr--8 txt--14"></i>
						Performance Comparison Reports
					</button>
					<button mat-button color="primary" [routerLink]="['/keywords']" [appUserFeature]="'searchTermsReportList'">
						<i class="fa fa-arrow-down-arrow-up mr--8 txt--14"></i>
						<span class="title-dark-launch ml--3">Keywords Reports</span>
					</button>
					<button mat-button color="primary" [routerLink]="['/review-report']" id="reviewReportsBtn">
						<i class="fas fa-stars mr--8 txt--14"></i>
						Reviews Reports
					</button>
					<button mat-button color="primary" [routerLink]="['/performance-revenue-report']" id="revenueCalcReportsBtn">
						<i class="fas fa-money-check-alt mr--8 txt--14"></i>
						Revenue Calculator Reports
					</button>
				</mat-menu>

			</div>

			<!--BULK ACTIONS-->
			<div class="menu__item">

				<button mat-button gmbBulkUpdateAddOn id="bulkActionsBtn" *ngIf="!(session$ | async).bulkActionsEnabled; else normalStructure">
					<i class="fas fa-tasks mr--8 txt--14"></i>
					<span class="menu__item__disabled">Bulk Actions</span>
					<span class="addon-mesage">Add-On</span>
				</button>

				<ng-template #normalStructure>
					<button mat-button [matMenuTriggerFor]="bulkactions" id="bulkActionsBtn" #normalStructure>
						<i class="fas fa-tasks mr--8 txt--14"></i>
						Bulk Actions
					</button>
				</ng-template>

				<mat-menu #bulkactions="matMenu" [overlapTrigger]="false" xPosition="before">
					<button mat-button color="primary" [routerLink]="['/local-posts']" id="postManagementBtn" class="d-none" [appUserFeature]="'legacyPost'">
						<i class="fas fa-sticky-note mr--8 txt--14"></i>
						<span>Post Management</span>
					</button>

					<button mat-button color="primary" [routerLink]="['/posts-management']" id="bulkPostManagementBtn" class="d-none" [appUserFeature]="'postManagement'">
						<i class="fas fa-sticky-note mr--8 txt--14"></i>
						<span class="title-dark-launch">Post Management</span>
					</button>
					
					<button mat-button color="primary" [routerLink]="['/local-bulk']" id="bulkUpdateBtn">
						<i class="fas fa-stream mr--8 txt--14"></i>
						<span>Bulk Updates</span>
					</button>
					<button mat-button color="primary" [routerLink]="['/qanda']" id="bulkUpdateBtn" [appUserFeature]="'qandaReportView'">
						<i class="fa fa-comments-alt mr--8 txt--14"></i>
						<span class="title-dark-launch">Questions and Answers</span>
					</button>
				</mat-menu>
			</div>

			<div class="menu__item">
				<button mat-button [routerLink]="['/review-assistant']" id="reviewAssistantBtn">
					<i class="fas fa-handshake mr--8 txt--14"></i>
					Review Assistant
				</button>
			</div>

			<!--ACCOUNTS-->
			<div class="menu__item" *ngIf="!(session$ | async).isMember">
				<button mat-button (click)="goToProfiles()" routerLinkActive="active" id="accountsBtn">
					<i class="fas fa-location-dot mr--8 txt--14"></i>
					Profiles
				</button>
			</div>

			<div class="menu__item" *ngIf="(session$ | async).isMember" >
				<button mat-button [routerLink]="['/accounts']" routerLinkActive="active" id="accounsBtn">
					<svg-icon src="/assets/images/icons/accounts-user-menu.svg" class="menu__item__icon"></svg-icon>
					Accounts
				</button>
			</div>

			<!--PROFILES-->
			<div class="menu__item d-none" [appUserFeature]="'profilesFeature'">
				<button mat-button [routerLink]="['/profiles']" routerLinkActive="active" id="profilesBtn">
					<i class="fas fa-location-dot mr--8 txt--14"></i>
					Profiles
				</button>
			</div>

			<!--NOTIFICATIONS-->
			<!-- <div *ngIf="(session$ | async).isAdmin" class="menu__item menu__item--notification">

				<button mat-button [matMenuTriggerFor]="notificationMenu" class="btn">
					<i class="far fa-bell txt--lg"></i>
					<mat-chip-list class="notification-chip notification-chip--absolute notification-chip--sm">
						<mat-chip color="warn" selected disabled>{{countNotification}}</mat-chip>
					</mat-chip-list>
				</button>

				<mat-menu #notificationMenu="matMenu" class="notification-menu" [overlapTrigger]="false"
						  xPosition="before">
					<div class="notification d-flex justify-content-between flex-column">
						<div class="notification__header d-flex justify-content-between">
							<h3 class="d-flex align-items-center">
								<strong>Notifications</strong>
								<mat-chip-list class="notification-chip ml--10">
									<mat-chip color="warn" selected disabled>{{countNotification}}</mat-chip>
								</mat-chip-list>
							</h3>
							<span>
								<button
									*ngIf="countNotification > 1"
									(click)='clearAllNotifications()'
									mat-flat-button
									color="primary"
									class="btn btn-icon mr--10">
										Clear All
								</button>
              				</span>
						</div>
						<div class="notification__content">
							<ng-container *ngIf="countNotification > 0; else emptyNotifications"  >
								<ng-container *ngFor='let n of notifications'>
									<a class="notification__item d-flex align-items-center justify-content-between">
										<div class="w--100" (click)='viewed(n)'>
											<ng-container  [ngSwitch]="n.type">
												<h6 class="notification__title" *ngSwitchCase="oldAuthFail || authFail || fetchLocationInfoData">
													<span class="d-block" style="font-weight: bold">{{n.message}}</span>
													<span class="d-block">{{n.location.locationName}}</span>
													<gmb-address *ngIf="n.location.location?.address"
															 [address]="n.location?.location?.address"></gmb-address>
												</h6>
												<h6 class="notification__title" *ngSwitchCase="informative">
													<span class="d-block" style="font-weight: bold">{{n.message}}</span>
												</h6>
												<h6 class="notification__title" *ngSwitchCase="type_protocol">
													<span class="d-block">{{n.message}}</span>
												</h6>
												<h6 class="notification__title" *ngSwitchDefault>
													<span class="d-block" style="font-weight: bold">Location Synced</span>
													<ng-container *ngIf="n.location">
														<span class="d-block">{{n.location.locationName}}</span>
														<gmb-address *ngIf="n.location.location?.address"
															 [address]="n.location?.location?.address"></gmb-address>
													</ng-container>
												</h6>
											</ng-container >
											<span class="notification__time">{{n.createAt.toDate() | oTimeAgo}}</span>
										</div>
										<span class="alternate-theme">
                      						<button
							  					*ngIf="countNotification > 0"
							  					(click)="deleteNotification(n)"
							  					mat-flat-button
							  					matTooltip="Delete Notification"
							  					matTooltipPosition="above"
                          matTooltipClass="tooltip tooltip--white"
							  					color="primary"
							  					class="btn btn-icon btn-txt--red">
                        							<i class="fas fa-trash-alt"></i>
                    						</button>
                    					</span>
									</a>
								</ng-container>
								<div class="notification__item text-center a" (click)="handleReload($event); $event.stopPropagation()" *ngIf="pagination.hasNext" [style.backgroundColor]="loadingNoti ? '#ddd' : '#39b54a'">
									<div class="w--100">
										<h3 style="text-align: center; color: #fff;">Load more <i class="fas fa-redo-alt" [ngClass]="{'fa-spin': loadingNoti}"></i></h3>
									</div>
								</div>
							</ng-container>
							<ng-template #emptyNotifications>
								<div class="notification__item no-hover">
									<h6 class="notification__title">You have no new notifications.</h6>
								</div>
							</ng-template>
						</div>
					</div>
				</mat-menu>

			</div> -->

			<!--QUESTIONS-->
			<div class="menu__item menu__item--questions">
				<a href="https://mapssupport.atlassian.net/servicedesk/customer/portal/1" target="_blank" rel="noopener">
					<i class="fa fa-question-circle txt--lg mr--10"></i>
					<!-- <span class="txt--14 fw--medium txt--support">Support</span> -->
				</a>
			</div>

			<!--PROFILE MENU-->
			<div class="menu__item menu__item--profile">

				<button mat-button [matMenuTriggerFor]="profileMenu" id="menuItemBtn">
					<div class="profile d-flex align-items-center justify-content-between">
						<div class="profile__img">
							<ng-container *ngIf="(session$ | async).user as user">
								<div class="circle circle--lg circle--green"
									 *ngIf="!user.photoURL">{{ user.displayName | slice:0:1 }}</div>
								<img default="/assets/images/unknown-avatar.png" src="{{user.photoURL}}"
									 alt="{{user.displayName}}"
									 width="40" height="40"
									 class="d-block"
									 *ngIf="user.photoURL">
							</ng-container>

						</div>
						<div class="profile__txt flex-grow-1 txt--left">
							<p class="mb--5">Hi,
								<span class="d-block"
									  *ngIf="(session$ | async).user as user"><strong>{{ (user.displayName?.length>18)? (user.displayName | slice:0:18)+'..':(user.displayName) }}</strong>
                </span>
							</p>
						</div>
						<mat-icon><em class="fal fa-angle-down txt--lg"></em></mat-icon>
					</div>
				</button>

				<mat-menu #profileMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="mat-menu-header">
					<button *ngIf="(session$ | async).isAdmin" mat-button color="primary" id="myUsersBtn"
							[routerLink]="['/settings']">
						<svg-icon src="/assets/images/icons/my-account.svg" class="menu__item__icon"></svg-icon>
						My Users
					</button>

					<button *ngIf="(session$ | async).isAdmin" mat-button color="primary"
							[routerLink]="['/settings/billing']" id="planAndBillingBtn">
						<svg-icon src="/assets/images/icons/settings.svg" class="menu__item__icon"></svg-icon>
						Plan & Billing
					</button>

					<button mat-button color="primary" (click)="navigationS.goToAdmin()"
							*ngIf="(session$ | async).isMasterAdmin" id="masterAdminBtn">
						<svg-icon src="/assets/images/icons/master-admin.svg" class="menu__item__icon"></svg-icon>
						Master Admin
					</button>

					<button mat-button color="primary" id="accounsBtn" *ngIf="!(session$ | async).isMember"
							[routerLink]="['/accounts']">
						<svg-icon src="/assets/images/icons/accounts-user-menu.svg" class="menu__item__icon"></svg-icon>
						Accounts
					</button>

					<div class="menu__item--fullwidth">
						<button (click)="navigationS.signOut()" mat-button color="primary" id="logoutBtn">
							<svg-icon src="/assets/images/icons/logout.svg" class="menu__item__icon"></svg-icon>
							Logout
						</button>
					</div>

				</mat-menu>

			</div>
		</div>
	</div>
  <div *ngIf="isReport && isShared" class="mr--10">
    <button class="btn btn--white btn--grade ml--15" (click)="navigationS.goToLogin()">Log In</button>
    <button mat-flat-button color="accent" class="btn btn--grade ml--20" (click)="navigationS.goToRegister()">Sign Up</button>
  </div>
</mat-toolbar>