import { __extends } from "tslib";
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
// dep
import { OnInit, EventEmitter } from '@angular/core';
// app
import { KeywordsFilterConfigService } from './../services/keywords-filter-config.service';
import { IDateRange, IFilterConfig, Granularity, IComparisonItem } from '../../../constants/data-picker';
// import { IReportFilterConfigData } from '../../../constants/report-filter-config-data';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var ReportsFilterComponent = /** @class */ (function (_super) {
    __extends(ReportsFilterComponent, _super);
    function ReportsFilterComponent(_keywordsFilterConfigS, _sessionS) {
        var _this = _super.call(this) || this;
        _this._keywordsFilterConfigS = _keywordsFilterConfigS;
        _this._sessionS = _sessionS;
        _this.isReport = false;
        _this.navigateBackEmitter = new EventEmitter(); // <- the parent should handle where to redirect
        _this.dateSelectionChanged = new EventEmitter();
        _this.initialized = false;
        _this.dateReady = false;
        _this.dateRangeObject = {
            dateRangeLimits: {
                minDate: null,
                maxDate: null
            },
            selectedRange: { minDate: null, maxDate: null },
            granularity: {
                start: Granularity.Year,
                depth: Granularity.Year
            },
            presetDateRanges: null,
            viewBy: Granularity.Day,
            comparisonPresets: null,
        };
        _this.comparisonPeriod = {
            comparisonID: null,
            name: 'None',
            enabled: true
        }; // <- use it to save the selected comparison period
        _this.session$ = _this._sessionS.session$;
        return _this;
    }
    ReportsFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscribeSafe(this._keywordsFilterConfigS.dateRestrictionSubject$, function (dateRestriction) {
            var _a, _b;
            console.log('dateRestriction', dateRestriction);
            _this._keywordsFilterConfigS.getDatePresets();
            _this._subscribeSafe(_this._keywordsFilterConfigS.datePresetsSubject$, function (data) {
                var _a;
                console.log('presets', data);
                if (((_a = data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    _this.dateRangeObject.presetDateRanges = data;
                }
            });
            if (dateRestriction) {
                _this.dateRangeObject.dateRangeLimits.minDate = dateRestriction.minDate;
                _this.dateRangeObject.dateRangeLimits.maxDate = dateRestriction.maxDate;
                console.log('initialDates updated');
                _this.dateReady = true;
                if (!_this.initialized) {
                    _this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({
                        // testing, we need to initialize with the default min/max dates (defined in doc) if possible
                        minDate: (_a = dateRestriction) === null || _a === void 0 ? void 0 : _a.minDate,
                        maxDate: (_b = dateRestriction) === null || _b === void 0 ? void 0 : _b.maxDate
                    });
                    _this.initialized = true;
                }
            }
            _this._subscribeSafe(_this._keywordsFilterConfigS.comparisonPresetsSubject$, function (data) {
                var _a;
                console.log('comparison', data);
                if (((_a = data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    _this.dateRangeObject.comparisonPresets = data;
                }
            });
        });
    };
    ReportsFilterComponent.prototype.dateChanged = function (event) {
        // Date().toISOString() creates the wrong date, 31/12/2023 if you select 01/01/2024 and you are in Tokyo (UTC+9)
        // Date().toLocaleDateString() creates the correct date, 01/01/2024 if you select 01/01/2024 and you are in Tokyo (UTC+9)
        // same scenario if you are in LA (UTC-8) or anywhere else in the world
        this.minDateSelected = event.startDate,
            this.maxDateSelected = event.endDate;
        // should prompt a report update
        this._keywordsFilterConfigS
            .updateFilterConfigAndGetReportData({
            minDate: event.startDate,
            maxDate: event.endDate
        }, this.comparisonPeriod);
        console.log('dateChanged', this.minDateSelected, this.maxDateSelected);
        this._keywordsFilterConfigS.validateComparisonPresets({ minDate: event.startDate, maxDate: event.endDate });
        this.dateSelectionChanged.emit({ minDate: event.startDate, maxDate: event.endDate });
    };
    ReportsFilterComponent.prototype.onComparisonchage = function (period) {
        this.comparisonPeriod = period;
        this._keywordsFilterConfigS.updateFilterConfigAndGetReportData({ minDate: this.minDateSelected, maxDate: this.maxDateSelected }, period);
    };
    ReportsFilterComponent.prototype.hasLastMonth = function () {
        return false; // TESTING
    };
    ReportsFilterComponent.prototype.navigateBack = function () {
        this.navigateBackEmitter.emit(true);
    };
    ReportsFilterComponent.prototype.onOpen = function () {
        console.log('datepicker component opened');
        // possible place to add footer injection through javascript, as there doesn't seem to be a native way of modifying the component's footer
    };
    return ReportsFilterComponent;
}(BaseComponent));
export { ReportsFilterComponent };
