/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./location-app.component";
var styles_LocationAppComponent = [i0.styles];
var RenderType_LocationAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationAppComponent, data: {} });
export { RenderType_LocationAppComponent as RenderType_LocationAppComponent };
export function View_LocationAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "box__item box__item--editable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex w--100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "w--100"]], [[2, "txt--medium-gray", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "w--100 txt--md fw--700 mb--10"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "w--100 txt--md"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUnderReview; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sectionName; _ck(_v, 4, 0, currVal_1); var currVal_2 = "No app url yet"; _ck(_v, 6, 0, currVal_2); }); }
export function View_LocationAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-app", [], null, null, null, View_LocationAppComponent_0, RenderType_LocationAppComponent)), i1.ɵdid(1, 114688, null, 0, i2.LocationAppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationAppComponentNgFactory = i1.ɵccf("app-location-app", i2.LocationAppComponent, View_LocationAppComponent_Host_0, { isUnderReview: "isUnderReview" }, {}, []);
export { LocationAppComponentNgFactory as LocationAppComponentNgFactory };
