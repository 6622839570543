import { OnInit } from '@angular/core';
var LocationActionsComponent = /** @class */ (function () {
    function LocationActionsComponent() {
        this.isUnderReview = false;
        this.sectionName = 'Actions';
        this.primaryAction = '';
        this.additionalActions = [];
    }
    Object.defineProperty(LocationActionsComponent.prototype, "actions", {
        set: function (data) {
            var _this = this;
            data.forEach(function (action) {
                if (action.primary) {
                    _this.primaryAction = action.description;
                }
                else {
                    _this.additionalActions.push(action.description);
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    ;
    LocationActionsComponent.prototype.ngOnInit = function () {
    };
    return LocationActionsComponent;
}());
export { LocationActionsComponent };
