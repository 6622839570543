import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-good-to-know',
  templateUrl: './location-good-to-know.component.html',
  styleUrls: ['./location-good-to-know.component.scss']
})
export class LocationGoodToKnowComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() goodToKnow = '';

  public sectionName = 'Good to Know';

  constructor() { }

  ngOnInit() {
  }

}
