import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-brand',
  templateUrl: './location-brand.component.html',
  styleUrls: ['./location-brand.component.scss']
})
export class LocationBrandComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() brand = null;

  public sectionName = 'Brand';
  

  constructor() { }

  ngOnInit() {
  }

}
