import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-actions',
  templateUrl: './location-actions.component.html',
  styleUrls: ['./location-actions.component.scss']
})
export class LocationActionsComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() set actions(data: {description: string, primary: boolean}[]) {
    data.forEach(action => {
      if (action.primary) {
        this.primaryAction = action.description;
      } else {
        this.additionalActions.push(action.description);
      }
    })
  };

  public sectionName = 'Actions';
  public primaryAction = '';
  public additionalActions = [];

  constructor() { }

  ngOnInit() {
  }

}
