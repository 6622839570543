import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ModalCategoryComponent} from "../../modal-category/modal-category.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-location-primary-categ',
  templateUrl: './location-primary-categ.component.html',
  styleUrls: ['./location-primary-categ.component.scss']
})
export class LocationPrimaryCategComponent implements OnInit {
  @Input() primaryCategory: any;
  @Input() additionalCategories: any = [];
  @Input() placeId: any;
  @Input() isEdit = true;
  @Input() regionCode = 'US';
  @Input() isUnderReview = false;
  @Input() isApple = false;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter()

  constructor(private dialog:MatDialog) { }

  ngOnInit() {
  }


  openCategoryDialog() {
    const dialogRef = this.dialog.open(ModalCategoryComponent, {
      width: '680px',
      data: {
        regionCode: this.regionCode,
        placeId: this.placeId,
        primaryCategory: this.primaryCategory,
        additionalCategory: this.additionalCategories
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.onUpdate.emit(true);
      }
    });
  }

}
