import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-app',
  templateUrl: './location-app.component.html',
  styleUrls: ['./location-app.component.scss']
})
export class LocationAppComponent implements OnInit {
  @Input() isUnderReview = false;

  public sectionName = 'App';

  constructor() { }

  ngOnInit() {
  }

}
