<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="progress">
    <mat-spinner></mat-spinner>
</div>

<app-dashboard-filter [progress]="progress" [accountId]="accountId" [isProgressFetch]="fetchProgress"
  [isProgressPush]="isProgressPush" typeDashboard="locInfo" [location]="location$ | async"
  (ClickEvent)="dispatchAction($event)"></app-dashboard-filter>

<ng-container *ngIf="!progress" class="p--20">
    <div class="container--full mt--0">
        <div class="table--shadow mb--30 pt--10 pb--10">
            <div class="mat-row mat-row--padding mat-row--border  bg--white">

                <div class="content__body">
                    <div class="table--shadow mb--30">
                        <div class="mat-row mat-row--padding justify-content-between align-items-center bg--white">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <div class="box">
                                        <section class="border-bottom">
                                            <app-location-name 
                                                [isApple]="true" 
                                                [locationName]="locationData?.locationName" 
                                                [placeId]="locationId" 
                                                [isEdit]="false" 
                                                [locationAddress]="locationData?.address"
                                                [verified]="locationData?.brand?.verified"
                                                (onUpdate)="updateLocation($event)"
                                            >
                                            </app-location-name>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-description [isApple]="true" [description]="locationData?.about" [isEdit]="false" (onUpdate)="updateLocation($event)"></app-location-description>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- photos section -->
                                            <app-location-photos-section></app-location-photos-section>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-address [isApple]="true" [address]="locationData?.address" (onUpdate)="updateLocation($event)" [isEdit]="false">
                                            </app-location-address>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-regular-hours [isApple]="true" [regularHours]="locationData?.hours" [placeId]="locationId" (onUpdate)="updateLocation($event)" [isEdit]="false"></app-location-regular-hours>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-phone [isApple]="true" [primaryPhone]="locationData?.phone"  (onUpdate)="updateLocation($event)" [isEdit]="false"></app-location-phone>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-url [isApple]="true" [url]="locationData?.website"  (onUpdate)="updateLocation($event)" [isEdit]="false"></app-location-url>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- actions -->
                                            <app-location-actions [actions]="locationData?.actions"></app-location-actions>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- showcase -->
                                            <app-location-showcase></app-location-showcase>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- app -->
                                            <app-location-app></app-location-app>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-primary-categ [isApple]="true" [placeId]="locationId" [primaryCategory]="locationData?.primaryCategory" [additionalCategories]="locationData?.additionalCategories" [isEdit]="false" (onUpdate)="updateLocation($event)"></app-location-primary-categ>
                                          </section>

                                        <section class="border-bottom">
                                            <!-- good to know -->
                                            <app-location-good-to-know [goodToKnow]="locationData?.goodToKnow"></app-location-good-to-know>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- apple status -->
                                            <app-location-status [status]="locationData?.status"></app-location-status>
                                        </section>

                                        <section>
                                            <!-- apple brand -->
                                            <app-location-brand [brand]="locationData?.brand"></app-location-brand>
                                        </section>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="bg--dark--white br--10 p--30 mb--30">
                                        <h4 class="w--100 txt--md fw--700 mb--30">Your business is live on Apple</h4>
                                        <button mat-stroked-button color="primary" class="btn--rounded txt--blue p--30 pt--5 pb--5">
                                            <div class="justify-content-center">
                                                <i class="fas fa-apple-alt pr--10"></i>
                                                <span class="signup-text txt--md">View Live Place Card</span>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="br--10 p--30 fw--medium txt--align--center mb--30" style="background: #F1F9F1;">
                                        <p>Cover Photo</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


